import { computed } from "vue";

export default {
  // notice: please use with SimpleInputMixin!
  // mixins: [SimpleInputMixin],
  inheritAttrs: false,
  props: {
    currencyList: {
      type: [Array],
      default: config.ecommerce.currency.availableCurrencies,
    },
  },
  data() {
    return {};
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    internalCurrencyList() {
      return this.currencyList.map((key) => ({ value: key, label: key }));
    },
    safeAttrs() {
      let base = { ...this.$attrs };
      delete base.class;
      delete base.validationMessage;
      return base;
    },
  },
  methods: {},
  created() {},
};
